import React from 'react';
import { Button, Grid, Typography, Link } from '@material-ui/core';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';

const useStyles = makeStyles({
    card: {
      maxWidth: 345,
      height:450,
      borderRadius:"100%",
    },
    media: {
      minHeight: 450
    },
    typographyStyle: {
      paddingTop:30
    },
    gridBack: {
      backgroundColor: "black"
    }
});

const ArtistImage = props =>{
    const classes = useStyles();

    return (
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <Card className={classes.card}>
              <CardMedia
                className={classes.media}
                image="https://lucindo.s3.us-east-2.amazonaws.com/Lucindo-Fidalgo.jpg"
                //title="Contemplative Reptile"
              />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" alignItems="center">
            <a href="https://www.instagram.com/lucindofidalgo/?hl=en">
              <InstagramIcon color="primary" fontSize="large" />
            </a>
            <a href="https://www.facebook.com/Lu-Fidalgo-1383410261734984">
              <FacebookIcon color="primary" fontSize="large"/>
            </a>
          </Grid>
        </Grid>
      </Grid>
  );
}

export default ArtistImage