import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Countdown from 'react-countdown';
import { Box, Grid } from '@material-ui/core'
import LoginActionButton from '../wax/LoginActionButton';

const useStyles = makeStyles((theme) => ({
  gridRoot:{
    width: "100%",
    textAlign: "center",
    fontSize:40,
  },
}));

const DropCountdown = (props) => {

  const classes = useStyles()

  const dropDate = props.date

  // Renderer callback with condition
  const countdownRenderer = ({ days, hours, minutes, seconds, completed }) => {

    if (completed) {
      return (
        <Box style={{width:"100%"}} display="flex" flexDirection="row-reverse" p={1} m={1}>
          <LoginActionButton setwaxaccount={props.setwaxaccount}/>
        </Box>
      )
    } else {
      // Render a countdown
      return(
        <Grid container className={classes.gridRoot}>
          <Grid item xs={12}>
          <span>{days < 10 ? `0${days}` : days }:{hours < 10 ? `0${hours}` : hours}:{minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}</span>
          </Grid>
        </Grid>         
      )
    }
  };
 

    return(
    <Countdown renderer={countdownRenderer} date={dropDate} />
    )
}

export default DropCountdown