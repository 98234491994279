const lucindo = [
    {
        dropImage:"https://ipfs.atomichub.io/ipfs/QmYi6JGUzPSGoBFE9GvXTDaVmpRb48scgWd9HHPdX4R7am"
    },
    {
        dropImage:"https://ipfs.atomichub.io/ipfs/QmbXECJw9N95rv9acuuWU1p7paetVAsDDqCbxh3XRj3t2K"
    },
    {
        dropImage:"https://ipfs.atomichub.io/ipfs/QmSbcZTotpHv4CWppv9trvVwkEArTNNbAswwMYeUgWJjSc"
    },
    {
        dropImage:"https://ipfs.atomichub.io/ipfs/QmYi6JGUzPSGoBFE9GvXTDaVmpRb48scgWd9HHPdX4R7am"
    },
    {
        dropImage:"https://ipfs.atomichub.io/ipfs/QmbXECJw9N95rv9acuuWU1p7paetVAsDDqCbxh3XRj3t2K"
    },
    {
        dropImage:"https://ipfs.atomichub.io/ipfs/QmSbcZTotpHv4CWppv9trvVwkEArTNNbAswwMYeUgWJjSc"
    },
    {
        dropImage:"https://ipfs.atomichub.io/ipfs/QmYi6JGUzPSGoBFE9GvXTDaVmpRb48scgWd9HHPdX4R7am"
    },
    {
        dropImage:"https://ipfs.atomichub.io/ipfs/QmbXECJw9N95rv9acuuWU1p7paetVAsDDqCbxh3XRj3t2K"
    },
    {
        dropImage:"https://ipfs.atomichub.io/ipfs/QmSbcZTotpHv4CWppv9trvVwkEArTNNbAswwMYeUgWJjSc"
    },
    {
        dropImage:"https://ipfs.atomichub.io/ipfs/QmYi6JGUzPSGoBFE9GvXTDaVmpRb48scgWd9HHPdX4R7am"
    },
    {
        dropImage:"https://ipfs.atomichub.io/ipfs/QmbXECJw9N95rv9acuuWU1p7paetVAsDDqCbxh3XRj3t2K"
    },
    {
        dropImage:"https://ipfs.atomichub.io/ipfs/QmSbcZTotpHv4CWppv9trvVwkEArTNNbAswwMYeUgWJjSc"
    },
]

export default lucindo