import * as AWS from 'aws-sdk'

//ConfigurationOptions
const configuration = {
  region: 'us-east-2',
  secretAccessKey: 'APC3qpenr+0t5I5PDPWqqjGC76xyQniOdYjfkEI1',
  accessKeyId: 'AKIAXRM6AQJJXFHJTW5E'
}

const submitEmail = (userEmail) => {
    AWS.config.update(configuration)

    const docClient = new AWS.DynamoDB.DocumentClient()

    var itemData = {
      email: userEmail,
      //userType: "collector"
    }

    var params = {
      TableName : 'User',
      Item: itemData
    }

    docClient.put(params, function (err, data) {
      if (err) {
          console.log('Error', err)
      } else {
          console.log('Success', data)
      }
    })
}

const updateUserRole = (userEmail, userRole) => {
  AWS.config.update(configuration)

  const docClient = new AWS.DynamoDB.DocumentClient()

  var params = {
    TableName:'User',
    Key:{
        "email": userEmail,
    },
    UpdateExpression: "set userType = :r",
    ExpressionAttributeValues:{
        ":r":userRole,
    },
    ReturnValues:"UPDATED_NEW"
  };

  docClient.update(params, function (err, data) {
    if (err) {
        console.log('Error', err)
    } else {
        console.log('Success', data)
    }
  })
}





export {
  submitEmail,
  updateUserRole
}
