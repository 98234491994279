const http = require("http")
const axios = require("axios")


//Get all the atomic hub assets from the wax wallet
const getAccountAssets = async (accountName) => {
  let userData = {}

  await axios
    .get(`https://wax.api.atomicassets.io/atomicassets/v1/accounts/${accountName}`)
    .then(response => {
      if(response.data.success){
        userData = response.data.data
        console.log(userData)
      }
    })
}

//Get all assets from the a specific collection
const getUserAssetsByCollection = async (accountName, collectionName) => {
  return await axios
    .get(`https://wax.api.atomicassets.io/atomicmarket/v1/assets?owner=${accountName}&collection_name=${collectionName}&page=1&limit=100&order=desc&sort=asset_id`)
    .then(response => {
      if(response.data.success)
        return response.data.data
      else
        return []
    })
}

const getAsset = async(assetId) => {
  return await axios
    .get(`https://wax.api.atomicassets.io/atomicmarket/v1/assets/${assetId}`)
    .then(response => {
      if(response.data.success)
        return response.data.data
      else
        return []
    })
}

export {
    getAccountAssets,
    getUserAssetsByCollection
}
