
import React, {useState, useEffect, useRef,} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import LuFidalgo from './collections/LuFidalgo'
import LandingPage from './pages/LandingPage'
import ReedemNFT from './pages/ReedeemNFT';


const App = () => {

  const [waxUserAccount, setWaxUserAccount] = useState()
  
  useEffect(async function () {
    document.title = "matiz.io"
  },[waxUserAccount])

  return (
    <div>
   

        <Router>
          <Switch>
            <Route exact path="/">
              <LandingPage/>
              </Route>
            <Route path="/ibisdoce">
                <LuFidalgo setwaxaccount={setWaxUserAccount} waxaccount={waxUserAccount}/>
            </Route>
            <Route path="/reedemnft">
              <ReedemNFT/>
            </Route>
          </Switch>
        </Router>

      
    </div>
  );
}

export default App;
