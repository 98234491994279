import React, { useState, useEffect } from 'react';
import lucindoPieces from '../../data/lucindo'
//import {Grid } from '@material-ui/core'
import '../../App.css'
import owlEyes from '../../assets/common/eyes.jpeg'

const shuffledPieces = (pieces, preview) => {
    const shuffled = [...pieces]

    if(!preview)
      shuffled.forEach((piece, i) =>{
          let j = Math.floor(Math.random() * (i + 1));
          [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
      })
    
    return shuffled;
}

const Jigsaw = (props) => {

  let previewJigsaw = props.preview

  //Preview state variables
  const [refAnimate, setRefAnimate] = useState(
    Array(12).fill(0).map(i=> React.createRef())
    //piecesToMove.map( () => { return React.createRef() })
  )

  const [cardPieces, setCardPieces] = useState(lucindoPieces.map((piece,i) => ({
        //img: `${piece.pieceImage}dw`,
      img: `${piece.dropImage}`,
      order: i,
      board: 'shuffled'
  })))

    const [cardShuffles, setCardShuffles] = useState(
        shuffledPieces(cardPieces, previewJigsaw)
    )

    //const [cardSolved, setCardSolved] = useState([...Array(16)])
    const [cardSolved, setCardSolved] = useState([...Array(12)])

    const runSimulation = async () => {
      setInterval(moveCards([refAnimate[6].current,refAnimate[7].current, refAnimate[10].current, refAnimate[11].current]), 5)
      
      // moveCard(refAnimate[1].current)
      // moveCard(refAnimate[10].current)

     //console.log(returnVal)


    }

    const moveCards = (elements) => {
      let eleList = [...elements]
      let idInterval = null;
      let posTop = 0;
      let posRight = 0;

      clearInterval(idInterval)

      idInterval = setInterval(frame, 5);

      function frame() {
        let moveEle = eleList[0]
        if (posTop == 175 && posRight == 198) {
          clearInterval(idInterval)
          eleList.shift()
          posTop = 0
          posRight = 0
          if(eleList.length !== 0)
            idInterval = setInterval(frame, 5);
          else
            moveCards(elements)
        }else{
          if(posTop !== 175) posTop++
          posRight++
          moveEle.style.top = `${posTop}px`;
          moveEle.style.right = `${posRight}px`;
        }
      }
    }

    const moveCard = (elem) => {
      //console.log("is this the  move", elem)
        let id = null;
        //const elem = document.getElementById("animate");
        let posTop = 0;
        let posRight = 0;
        console.log("right", posRight)
        clearInterval(id);
        id = setInterval(frame, 5);
        let done = false

        function frame() {
        //  console.log("the frame!", posTop)
          if (posTop == 175 && posRight == 198) {
            clearInterval(id);
            done = true
          } else {
            if(posTop !== 175) posTop++;
            posRight++; 
            elem.style.top = `${posTop}px`;
            elem.style.right = `${posRight}px` 
          }
        }

        return "radius"
    }


    useEffect(()=>{
      if(previewJigsaw)
        runSimulation()
    },[])

    const handleDragStart = (e, order) => {
      console.log("dragging", order)
        e.dataTransfer.setData('text/plain', order);
    }

    const handleDrop = (e, index, targetName) => {

      console.log("targetName", targetName)
      console.log("index", index)
      // console.log("cardShuffles",cardShuffles)
      // console.log("cardSolved",cardSolved)
        let target = []

        if(targetName === "suffled")
            target = cardShuffles
        else
            target = cardSolved


        if (target[index]) return;


        const pieceOrder = e.dataTransfer.getData('text');
        const pieceData = cardPieces.find(p => p.order === +pieceOrder);
        let origin = []

        console.log("pieceOrder",pieceOrder)
        console.log("pieceData",pieceData)

        if(pieceData.board === "shuffled")
            origin = cardShuffles;
        else
            origin = cardSolved

        if (targetName === pieceData.board) target = origin;
        origin[origin.indexOf(pieceData)] = undefined;
        target[index] = pieceData;
        pieceData.board = targetName;


        if(pieceData.board === 'shuffled')
            setCardShuffles([...origin]);
        else
            setCardSolved([...origin]);

        if(targetName === 'shuffled')
            setCardShuffles([...target]);
        else
            setCardSolved([...target]);

      }
    
    const renderPieceContainer = (piece, index, boardName) => {
        // console.log("index", index)
        // console.log("boardName", boardName)
        // console.log("piece", piece)
        if(boardName === "shuffled"){
          return (
            <li key={`${boardName} ${index}`} 
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => handleDrop(e, index, boardName)
            }
            style={{height:153, width:97}}
            // className = {boardName === 'shuffled'?'.jigsaw__li-shuffled':'.jigsaw__li-shuffled'}
            // className=".jigsaw__li-shuffled"
            >
          {/* style={boardName === 'shuffled' && piece !== undefined? {backgroundImage:`url(${piece.img})`}:{backgroundImage:``}}> */}
              {piece && <img  alt="nft" ref={refAnimate[index]} draggable onDragStart={(e) => handleDragStart(e, piece.order)} src={piece.img}/>}
            </li>
          );
        }
        else{
          return (
            <li key={`${boardName} ${index}`} 
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => handleDrop(e, index, boardName)
            }
            style={{height:153, width:97}}
            // className = {boardName === 'shuffled'?'.jigsaw__li-shuffled':'.jigsaw__li-shuffled'}
            // className=".jigsaw__li-shuffled"
            >
          {/* style={boardName === 'shuffled' && piece !== undefined? {backgroundImage:`url(${piece.img})`}:{backgroundImage:``}}> */}
              {piece && <img alt="nft" draggable onDragStart={(e) => handleDragStart(e, piece.order)} src={piece.img}/>}
            </li>
          );
        }
    } 

    return (
        <div className="jigsaw">
          <ul className="jigsaw__shuffled-board" style={{zIndex: 999}}>
            {
              cardShuffles.map((piece, i) =>
              renderPieceContainer(piece, i, 'shuffled'))
            }
          </ul>
          <ol className="jigsaw__solved-board" style={{backgroundImage:`url(${owlEyes})`}} >
            {
              cardSolved.map((piece, i) =>
              renderPieceContainer(piece, i, 'solved'))
            }
          </ol>
        </div>
      );
}





export default Jigsaw