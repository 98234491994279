import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CardMedia, Card, CardContent, Typography, Grid } from '@material-ui/core';
import DropActionButton from '../DropActionButton';
import pack from '../../assets/common/pack.jpeg'
import DropPackOpening from './DropPackOpening';
import DropArtist from '../DropArtist';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "50px",
        position: "sticky",
    },
    media: {
        height: 550,
        objectFit: 'contain',
        marginBottom: "40px"
    },
    typographySection: {
        marginBottom: theme.spacing(5)
    }
  }))

const DropPack = (props) => {
    const classes = useStyles();

    return(
        <Card className={classes.root}>
            <Grid container >
                <Grid item xs={6}>
                    <div>
                        <CardMedia
                        component="img"
                        //image="https://ipfs.atomichub.io/ipfs/QmaFHQPzCyF5Tm2juWThTqAW9o769BsmHgthEDD7rriB8L"
                        image={pack}
                        className={classes.media}
                        />
                        <DropPackOpening packs={props.packs} packimages={props.packimages}/>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <CardContent style={{justifyContent: "center"}}>
                        <Typography variant="h3" align="center" className={classes.typographySection}>
                            IBIS
                        </Typography>
                        <Typography variant="body1" align="center" className={classes.typographySection}>The Twelve Collection brings the vibrant colors of LuFidalgo splendor 
              thoughts. A connection that highlights the power and ability to create your own world.  
                        </Typography>
                        <Typography variant="body1" align="center" className={classes.typographySection}>Twelve is the number that revolves around 12 protagonists from 4 
              different worlds with 1 purpose. Twelve is the number that composes basic life and desires. Twelve is the base of 
              this collection, the union of the body and mind to find a balance between cold and warm.
                        </Typography>
                        <Typography variant="body1" align="center" className={classes.typographySection}>The collection will be composed of 12 NFT's that merge into one another to 
              build a complete 12 set painting. The collector would have the opportunity to blend their complete collection in 
              this first drop to get the ULTIMATE SUPER RARE NFT named IBIS, the purpose.</Typography>
                        <DropActionButton/>
                    </CardContent>
                </Grid>
            </Grid>
        </Card>
    )
}

export default DropPack;