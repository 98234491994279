import React, {useState, useEffect} from 'react';
import '../App.css';
import * as waxjs from "@waxio/waxjs/dist";
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Typography, Box, Card, CardMedia, Paper} from '@material-ui/core'

//Components
import DropHeader from '../components/DropHeader';
import DropCollection from '../components/DropCollection';
import DropArtist from '../components/DropArtist';
import Jigsaw from '../components/jigsaw/Jigsaw'
import CollectionRoadmap from '../components/collection/CollectionRoadmap';
import Sponsors from '../components/common/Sponsors';

//Images
import owleyes from '../../src/assets/common/eyes.jpeg'
import DropPack from '../components/common/DropPack';

import { getUserAssetsByCollection } from "../services/AtomicHubRequests"

const useStyles = makeStyles((theme) => ({
  gridSections: {
    paddingTop:theme.spacing(10),
    backgroundColor:"white",
    position:"sticky"
  },
  gridFirstSection: {
    marginTop:theme.spacing(55),
    marginBottom: theme.spacing(13),
  },
  gridFirstHeading:{
    paddingTop:theme.spacing(10),
    position:"sticky",
    backgroundColor:"white"
  },
  gridHeading:{
    position:"sticky",
    backgroundColor:"white"
  },
  gridHeader: {
    marginBottom: theme.spacing(3)
  },
}));

const LuFidalgo = (props) => {
    const classes = useStyles()

    const [waxUserCollection, setWaxUserCollection] = useState()
    const [waxUserAccount, setWaxUserAccount] = useState()
    const [waxUserPacks, setWaxUserPacks] = useState()
    const [waxUserPackImages, setWaxUserPackImages] = useState()
    const [waxUserPackMintNum, setWaxUserPackMintNum] = useState()

    const getUserPacks = async () => {
      //Search for the collection
      const userAssets = await getUserAssetsByCollection(props.waxaccount,"stf.capcom")

      const packs = []
      const packsImages = []
      const packMintNumer = []

      userAssets.forEach(assets => {
       if(assets.template.template_id === "209819" || assets.template.template_id === "209718")
        packs.push(assets.asset_id)
        packsImages.push(assets.template.immutable_data.img)
        packMintNumer.push(assets.template_mint)
      })
      
      setWaxUserPacks(packs)
      setWaxUserPackImages(packsImages)
      setWaxUserPackMintNum(packMintNumer)

    }

    const updateWaxUser = async () => {
      //Set up the wax user acount
      setWaxUserAccount(props.waxaccount)
    }
  
    useEffect(async function () {
      if(props.waxaccount){
        updateWaxUser()
        getUserPacks()
      }
    }, [props.waxaccount]);
  
    return (
      <div>
        <Card style={{maxHeight:'900px', position:"fixed"}} >
              <CardMedia
                component="img"
                image={owleyes}
                style={{opacity:1, objectFit: "cover"}}/>
        </Card>
        <Grid container direction='column'>
          <Grid item>
            <DropHeader setwaxaccount={props.setwaxaccount}/>
          </Grid>
          <Grid item container className={classes.gridFirstSection}>
            <Grid item lg={2}/>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <DropPack packs={waxUserPacks} packimages={waxUserPackImages}/>
            </Grid>
            <Grid item lg={2}/>
          </Grid>
          <Grid item container className={classes.gridFirstHeading}>
            <Grid item  sm={2}/>
            <Grid item xs={12} sm={8}>
              <Typography variant="h2" component="h3">
                The Collection
              </Typography>
            </Grid>
            <Grid item  sm={2}/>
          </Grid>
          <Grid item container className={classes.gridSections} style={{paddingBottom:100}}>
            <Grid item lg={2}/>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <DropCollection/>
            </Grid>
            <Grid item lg={2}/>
          </Grid>
          <Grid item container className={classes.gridHeading} style={{backgroundColor:"#4D4D4D", paddingTop:20}} >
            <Grid item  sm={2}/>
            <Grid item xs={12} sm={8}>
              <Typography variant="h2" component="h3" color="secondary">
                The Artist
              </Typography>
            </Grid>
            <Grid item  sm={2}/>
          </Grid>
          <Grid item container className={classes.gridSections} style={{backgroundColor:"#4D4D4D", paddingBottom:50}}>
            <Grid item  sm={2}/>
            <Grid item xs={12} sm={8}>
              <DropArtist/>
            </Grid>
            <Grid item  sm={2}/>
          </Grid>
          <Grid item container className={classes.gridHeading} style={{paddingTop:20}}>
            <Grid item sm={2}/>
            <Grid item xs={12} sm={8}>
              <Typography variant="h3" component="h3">
                More than a collection of NFT'S
              </Typography>
            </Grid>
            <Grid item  sm={2}/>
          </Grid>
          <Grid item container  style={{paddingBottom:30, position: "sticky", backgroundColor: "white"}}>
            <Grid item lg={2}/>
            <Grid item xs={12} lg={8} >      
              <Jigsaw preview={true}/>      
              {/* <CollectionPreviewPuzzle callback={expandJigsawPreview}/> */}
            </Grid>
            <Grid item lg={2}/>
          </Grid>
          <Grid item container className={classes.gridHeading} style={{paddingTop:20}}>
            <Grid item sm={2}/>
            <Grid item xs={12} sm={8}>
              <Typography variant="body1" component="h3">
                You will have the chance to collect all 12 unique NFT's to blend it to the ULTIMATE NFT.
              </Typography>
            </Grid>
            <Grid item  sm={2}/>
          </Grid>
          <Grid item container className={classes.gridHeading} style={{paddingTop:100}}>
            <Grid item sm={2}/>
            <Grid item xs={12} sm={8}>
              <Typography variant="h2" component="h3">
                Collection Roadmap
              </Typography>
            </Grid>
            <Grid item  sm={2}/>
          </Grid>
          <Grid item container className={classes.gridSections} style={{paddingTop:20}}>
            <Grid item sm={2}/>
            <Grid item xs={12} sm={8}>
              <div>
                <Box position='absolute'/>
                <CollectionRoadmap/>
              </div>
              
            </Grid>
            <Grid item sm={2}/>
          </Grid>
          {/* <Grid item container className={classes.gridSections}>
            <Grid item  sm={2}/>
            <Grid item xs={12} sm={8}>
            <p>Loading {progression * 100} percent...</p>
            <Unity style={{justifyContent: "center",height:"400px", width:"400px", backgroundColor:"black"}} unityContext={unityContext} />
            </Grid>
            <Grid item  sm={2}/>
          </Grid> */}
        </Grid>
        
      </div>
    );
  }
  
  export default LuFidalgo;
  