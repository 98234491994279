import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import atomicassets from '../../assets/common/atomicassets.png'
import waximage from '../../assets/common/wax.png'

const useStyles = makeStyles({
    cardWax: {
        maxWidth:250
    },
    cardAtomic: {
      maxWidth:120,
      backgroundColor:"black"
    }
});

const Sponsors = props =>{
    const classes = useStyles();

    return (
      <Grid container direction="column" spacing={6}>
        <Grid container item xs={12}>
        <Typography variant="subtitle1">
            Powered by
          </Typography>
        </Grid>
        <Grid container direction="row" spacing={2}>
          <Grid container item xs={6} >
            <img className={classes.cardAtomic} src={atomicassets} alt="Logo" />
          </Grid>
          <Grid container item xs={6}>
            <img className={classes.cardWax} src={waximage} alt="Logo" />
          </Grid>
        </Grid>
      </Grid>
  );
}

export default Sponsors