import React from 'react';
import DropActionButton from './DropActionButton';
import { Typography, Toolbar, AppBar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LoginActionButton from './wax/LoginActionButton';
import DropCountdown from './common/DropCountdown';
import DropPackOpening from './common/DropPackOpening';

const useStyles = makeStyles((theme) => ({
  typographyStyle: {
    justifyContent: "center",
    width: "100%",
  },
}));



const DropHeader = props =>{
  const classes = useStyles()

  return (
    <AppBar position="sticky" color="primary">
      <Toolbar>
        {/* <DropCountdown className={classes.typographyStyle} date="2021-08-12T13:00:00"/> */}
        <DropCountdown setwaxaccount={props.setwaxaccount} className={classes.typographyStyle} date="2021-07-12T13:00:00"/>
      </Toolbar>
    </AppBar>
  );
}

export default DropHeader