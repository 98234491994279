import React, {useState}  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import ReactCardFlip from 'react-card-flip';
import CollectionsIcon from '@material-ui/icons/Collections';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

const useStyles = makeStyles({
  card: {
    width: 324,
    transition: "transform 0.15s ease-in-out"
  },
  media: {
    height: 451,
    objectFit: 'cover'
  },
  cardHovered: {
    transform: "scale3d(1.10, 1.10, 1)"
  }

});

const DropCard = props => {
  const classes = useStyles();

  const {dropImage} = props
  const [flipped, setFlipped] = useState(false)
  const [cardShadow, setCardShadow] = useState(1)
  const [cardRaisedFront, setCardRaisedFront] = useState(false)
  const [cardRaisedBack, setCardRaisedBack] = useState(false)
  const [totalOfCards, setTotalOfCards] = useState("3x")

  const onMouseOverFront = () => { 
    setCardShadow(3)
    setCardRaisedFront(true)
  }
  const onMouseOutFront = () => { 
    setCardRaisedFront(false)
    setCardShadow(1) 
  }

  const onMouseOverBack = () => { 
    setCardShadow(3)
    setCardRaisedBack(true)
  }
  const onMouseOutBack = () => { 
    setCardRaisedBack(false)
    setCardShadow(1) 
  }

  const flipToBack = () => {
    onMouseOutFront()
    setFlipped(true)
  }

  const flipToFront = () => {
    onMouseOutBack()
    setFlipped(false)
  }

  return (
    <div>

    
    <ReactCardFlip isFlipped={flipped}>
        <Card 
          className={classes.card}
          classes={{root: cardRaisedFront ? classes.cardHovered : ""}}
          zdepth={cardShadow} 
          raised={cardRaisedFront} 
          onMouseOver={onMouseOverFront} 
          onMouseOut={onMouseOutFront}>
          <CardActionArea onClick={flipToBack}>
            <CardMedia
              component="img"
              className={classes.media}
              image={dropImage}
              //title="Contemplative Reptile"
            />
          </CardActionArea>
        </Card>

        <Card 
          className={classes.card}
          classes={{root: cardRaisedBack ? classes.cardHovered : ""}}
          zdepth={cardShadow} 
          raised={cardRaisedBack} 
          onMouseOver={onMouseOverBack} 
          onMouseOut={onMouseOutBack}>
          <CardActionArea onClick={flipToFront}>
            <CardMedia
              component="img"
              className={classes.media}
              image={`https://lucindo.s3.us-east-2.amazonaws.com/lucindo_jigsaw/daiya-3-3.png`}
              //title="Contemplative Reptile"
            />
          </CardActionArea>
        </Card>
      </ReactCardFlip>
      <div>
        {totalOfCards}<CollectionsIcon color="primary"/> <ShoppingCartIcon color="primary"/>
      </div>
      </div>
    );
}

export default DropCard