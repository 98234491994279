import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import DropActionButton from './DropActionButton';
import ArtistImage from './artist/ArtistImage';

const useStyles = makeStyles({

});

const DropArtist = props =>{
    const classes = useStyles();

    return (
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item xs={12} sm={6}>
          <Grid container direction="row" alignItems="center" spacing={4}>
            <ArtistImage/>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container direction="row" alignItems="center" spacing={4} >
            <Grid item xs={12}>
              <Typography variant="body1" color="secondary"><b style={{fontSize:"30px"}}>Lu Fidalgo</b> es una artista y diseñador, nacido en Boston y criado en  la capital de  Puerto Rico. 
              Lu es la mezcla de un padre caboverdiano y una madre puertorriqueña y comparte una mezcla de culturas: africana, puertorriqueña y americana. 
              Obtuvo bachillerato en Artes de la Comunicación de la Universidad de Puerto Rico y luego decidió viajar el mundo como creativo.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" color="secondary">Lu se inspira en las personas: cultura, comportamiento, entretenimiento, relaciones y visiones culturales del mundo. Su vívida creatividad le 
                ha dado a Lu un nombre en la escena de la música latina urbana, influyendo en los músicos mientras se ve influenciado en este entorno, 
                creando un círculo completo</Typography>
            </Grid>
           <Grid item xs={12}>
              <DropActionButton/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
  );
}

export default DropArtist