import React from 'react';
import { Button } from '@material-ui/core';

const DropActionButton = props =>{
  return (
    <div style={{width:"100%", justifyContent:"center", display:"flex"}}>
        <Button href="https://wax.atomichub.io/drops/28807+28802+28795+28793+28792+28790" variant="contained" color="primary">
          Join The Drop
        </Button>
    </div>
  );
}

export default DropActionButton
