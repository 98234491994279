import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import roadmap from '../../assets/drops/lucindo/roadmap.png'

const useStyles = makeStyles({
    card: {
   //     height: 500
    },
    media: {
      minHeight: 500,
      objectFit: 'cover'
    },
});

const CollectionRoadmap = props =>{
    const classes = useStyles();

    return (
      <Grid container direction="row" alignItems="center">
        <Grid item xs={12}>
          <Card className={classes.card}>
              <CardMedia
                className={classes.media}
                image={roadmap}
                //title="Contemplative Reptile"
              />
          </Card>
        </Grid>
      </Grid>
  );
}

export default CollectionRoadmap