import React, {useState, useEffect} from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

//Wax library
import * as waxjs from "@waxio/waxjs/dist";

//Atomic Assets
import {ExplorerApi, RpcApi} from "atomicassets"

const useStyles = makeStyles((theme) => ({
    divParent:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        
    },
    cardActionWallet:{
        display: "flex",
        justifyContent: "center"
    }
}))

const bull = (
    <Box
      component="img"
      sx={{
        maxHeight: { xs: 500, md: 500, lg:500 },
      }}
      alt="Happy Engagement"
      src="https://gateway.pinata.cloud/ipfs/QmRf6FNvnYs1iWop6rrjS7sNYvBhxq5fVqxDaQdbQxNbzJ"
    />
  );




const ReedemNFT = () => {
    const classes = useStyles()
    const wax = new waxjs.WaxJS('https://wax.greymass.com', null, null, false);
    //const api = new RpcApi("https://testnet.wax.pink.gg", "atomicassets", {fetch, rateLimit: 4});
    const api = new ExplorerApi("https://test.wax.api.atomicassets.io", "atomicassets", {fetch});
    const [waxAccountName, setWaxAccountName] = useState()

    const loginToWax = async () => {

        try{
            const userAccount = await wax.login();

            setWaxAccountName(userAccount)

            //getAccountInventory()
            mintAsset()

        }catch(e){
            console.log(e)
        }
    }

    const getAccountInventory =  async () => {
        console.log("getting inventory")
        try{
           // const inventory = await api.("waxsc1next12")
            //console.log("inventory", inventory);
        }catch(e){
            console.log(e)
        }

        
    }

    const getCollectionName = async () => {
        
        const collection = await api.getCollection("matizdotiony")

        console.log("collection", collection);
    }

    const mintAsset = async () => {

        // const actions = await api.action.mintasset(
        //     [{actor: "waxsc1next12", permission: "active"}],
        //     "waxsc1next12","matizdotiony","weddinganno3",-1,"waxsc1next12",{},{},[]
        // )

        // create the action to mint an asset
        try{
        const actions =  await (await api.action).mintasset(
            [{actor: "waxsc1next14", permission: "active"}],
            -1, "matizdotiony", "weddinganno3", "waxsc1next12", {"name": "test"}, {"species": "test2"}
        )

       // console.log("actions", actions)
        }catch(e){
            console.log(e)
        }

    }


    


    

    return(
        <div className={classes.divParent}>
        <Card>
        <CardContent>
            {bull}
            {!waxAccountName ? <Typography align='center'>
                Connect Wallet to get Your NFT
            </Typography> : <Typography align='center'>
                Welcome {waxAccountName}
            </Typography>}
            
        </CardContent>
        <CardActions className={classes.cardActionWallet}>
            <Button size="small" onClick={loginToWax}>Connect Wallet</Button>
        </CardActions>
        </Card>
        </div>
    )

}

export default ReedemNFT