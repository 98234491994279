import React from 'react';
import DropCard from './DropCard';
import { Grid } from '@material-ui/core'
import lucindoCollection from '../data/lucindo'

const getCollection = (collectionObj) => {

    return (
        <Grid item xs={12} sm={6} md={4}>
            <DropCard { ...collectionObj }/>
        </Grid>
        )
}

const DropCollection = () => {

    return(
        <Grid container spacing={5}>
            {lucindoCollection.map( lucindoObj => getCollection(lucindoObj))}
        </Grid>
    )

}

export default DropCollection