import React, {useState} from 'react';
import { Button } from '@material-ui/core';
import * as waxjs from "@waxio/waxjs/dist";

const LoginActionButton = props =>{
    const [waxAccountName, setWaxAccountName] = useState("Login")
    const [waxPublicKeys, setWaxPublicKeys] = useState()

    const LoginWax = async () => {
        const wax = new waxjs.WaxJS('https://wax.greymass.com', null, null, false);
      
        var isAutoLoginAvailable = await wax.isAutoLoginAvailable();
      
        if(!isAutoLoginAvailable){
          const userAccount = await wax.login();
          setWaxAccountName(userAccount)
    
          const pubKeys = wax.pubKeys;
          setWaxPublicKeys(pubKeys)

          //const userAssets = await getUserAssetsByCollection(userAccount,"stf.capcom")

          //Set User Wax Account Information
          // const userWaxAccount = [{
          //   waxaccountname: userAccount,
          //   collectionAssets: userAssets
          // }]

          props.setwaxaccount(userAccount)

        }else{
          console.log("isAutoLoginAvailable", isAutoLoginAvailable)
        }
       
    }

    return (
            <Button onClick={ LoginWax } variant="contained" color="secondary">
            {waxAccountName}
            </Button>
    );

    
}



export default LoginActionButton
