import { createTheme } from '@material-ui/core/styles'
import { purple } from '@material-ui/core/colors'

const theme = createTheme({
    palette: {
        background:{
            default: '#00BEC5'
        },
        primary: {
            main: '#00BEC5',
            contrastText: '#ffffff'
        },
        secondary: {
            main: '#ffffff',
            contrastText: "#9C27B0"
        },
    },
    status: {
        danger: 'orange'
    },
    typography: {
        fontFamily: [
          'Lato',
          'sans-serif;'
        ].join(','),
      }
})

export default theme;