
import { Typography, TextField, Button, Box, AppBar, Toolbar, Link, 
    Card, CardMedia, CardContent, Collapse, CardActionArea, Fade, Dialog, DialogTitle } from '@material-ui/core';
  import React, {useState, useEffect, useRef,} from 'react';
  import InstagramIcon from '@material-ui/icons/Instagram';
  import desktopBackground from '../assets/matiz/desktop_landing_page_bkgr.png'
  import mobileBackground from '../assets/matiz/mobile_landing_page_bkgr.png'
  import tabletBackground from '../assets/matiz/tablet_landing_page_bkgr.png'
  import CssBaseline from "@material-ui/core/CssBaseline";
  import { makeStyles } from '@material-ui/core/styles';
  import waximage from '../assets/common/wax-logo-white.png'
  import userCollector from '../assets/matiz/mobile_collector.png'
  import userCreator from '../assets/matiz/mobile_creator.png'
  import userInvestor from '../assets/matiz/mobile_investor.png'
  import { submitEmail, updateUserRole} from '../services/AWSDynamo';
  
  //Adding this change for Amplify
  
  const useStyles = makeStyles((theme) => ({
    boxAppStyle:{
      [theme.breakpoints.between('xs','sm')]: {
        backgroundImage: `url(${mobileBackground})`,
      },
      [theme.breakpoints.between('md', 'lg')]: {
        backgroundImage: `url(${tabletBackground})`,
      },
      [theme.breakpoints.up('xl')]: {
        backgroundImage: `url(${desktopBackground})`,
      },
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      minHeight: '100vh',
  
    },
    appBarStyle:{
      [theme.breakpoints.between('xs','md')]: {
        paddingLeft:15,
        paddingTop: 20,
      },
      [theme.breakpoints.up('lg')]: {
        paddingLeft:75,
        paddingTop: 20,
      },
      marginTop: "10px", 
      background: 'transparent'
    },
    textField: {
      [theme.breakpoints.between('xs','md')]: {
        width: 200,
      },
      width: 300,
      "& .MuiInputBase-root": {
        background: "rgb(250, 250, 250)",
        height: 50,
        borderRadius: 0,
        border:"none",
      }
    },
    typographyStyle:{
      [theme.breakpoints.between('xs','md')]: {
        fontSize: 40,
      },
    },
    typographyWeCareStyle:{
      [theme.breakpoints.between('xs','md')]: {
        fontSize: 25,
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
    imageWaxStyle: {
      [theme.breakpoints.between('xs','md')]: {
        width: '50%'
      }
    },
    boxStyle: {
      [theme.breakpoints.between('xs','md')]: {
        paddingTop: 150,
        paddingRight: 40,
        paddingLeft: 40
      },
      [theme.breakpoints.up('lg')]: {
        paddingTop: 250,
        paddingLeft: 100,
        width:'60%'
      },
    },
    boxDescriptionStyle: {
      [theme.breakpoints.between('xs','md')]: {
        paddingLeft: 40,
        width:'100%'
      },
      [theme.breakpoints.up('lg')]: {
        paddingLeft: 100,
        width:'60%'
      },
    },
    boxInputStyle:{
      [theme.breakpoints.between('xs','md')]: {
        paddingLeft: 40,
        width:'100%'
      },
      [theme.breakpoints.up('lg')]: {
        paddingLeft: 100,
        width:'60%'
      },
    },
    boxOnlyMobile:{
      [theme.breakpoints.between('xs','sm')]: {
        display:"flex"
      },
      [theme.breakpoints.up('md')]: {
        display:"none"
      }
    },
    boxOnlyDesktop:{
      [theme.breakpoints.between('xs','sm')]: {
        display:"none"
      },
      [theme.breakpoints.up('md')]: {
        display:"flex"
      },
      paddingRight: 75
    },
    cardWhoUser:{
      [theme.breakpoints.between('xs','sm')]: {
        width: "95px",
        height:"120px",
        marginRight: "15px",
        padding:"10px"
      },
      [theme.breakpoints.between('md','lg')]: {
        width: "190px",
        height:"240px",
        marginRight: "15px",
        padding:"30px"
      },
      '&:hover': {
        backgroundColor: "#DC3DAA",
      },
        backgroundColor:"black"
    },
    cardmediaWhoUser:{
      [theme.breakpoints.between('xs','sm')]: {
        height:"80px"
      },
      [theme.breakpoints.between('md','lg')]: {
        height:"140px"
      },
    },
    waxDialogBox: {
      backgroundColor: 'black',
      padding:"30px",
      [theme.breakpoints.between('md','lg')]: {
        width:"800px"
      },
    },
    typographyUserRole:{
      [theme.breakpoints.between('xs','sm')]: {
        fontSize:"14px"
      },
    },
  }));
  
  const LandingPage = () => {
    const classes = useStyles()
  
    const [waxUserAccount, setWaxUserAccount] = useState()
    const [userEmail, setUserEmail] = useState('');
    const [showUserWho, setUserWho] = useState(false)
    const [userRole, setUserRole] = useState()
    const [userRegister, setUserRegister] = useState(false)
    const [openWaxDialog, setOpenWaxDialog] = useState(false)
    const [openUserRoleDialog, setOpenUserRoleDialog] = useState(false)
  
    const myRefWhoRU = useRef(null)
    const myRefWeCare = useRef(null)
    const myRefApp = useRef(null)
    
    useEffect(async function () {
      document.title = "matiz.io"
  
      if(userRole)
        updateUserRole(userEmail,userRole)
    },[waxUserAccount, userRole])
  
    const openWhoRUSection = (userEmail) => {
      //Validate the email
      if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(userEmail)) 
      {
        submitEmail(userEmail);
        setOpenUserRoleDialog(true)
        //myRefWhoRU.current.scrollIntoView({behavior: "smooth"})
      }
    }
  
    const handleClose = () => {
      setOpenWaxDialog(false)
    }
  
    const handleCloseUserRole = () => {
      setOpenUserRoleDialog(false)
      setUserRegister(false)
      setUserRole(false)
      setUserEmail('')
    }
  
    return (
      <div>
        <Box ref={myRefApp} component="div" className={classes.boxAppStyle}>
          <CssBaseline/>
  
          <AppBar position="absolute" elevation={0} className={classes.appBarStyle}>
            <Toolbar>
              <Box display="flex" style={{width:"100%"}}>
                <img style={{width:'100px', height:'80px'}} src="https://matiz.s3.us-east-2.amazonaws.com/this/matiz_white.PNG" alt="matiz"/>
              </Box>
              <Box display="none" className={classes.boxOnlyDesktop}>
                {/* <TelegramIcon style={{marginRight:"10px"}}/> */}
                <Link href="https://www.instagram.com/matiznft/">
                  <InstagramIcon style={{marginRight:"10px"}}/>
                </Link>
                {/* <TwitterIcon style={{marginRight:"10px"}}/>
                <FacebookIcon style={{marginRight:"10px"}}/>
                <LinkedInIcon style={{marginRight:"10px"}}/> */}
              </Box>
            </Toolbar>
          </AppBar>
  
          <Box className={classes.boxStyle}>
            <Typography variant="h2" className={classes.typographyStyle} color="secondary">
                Turning <b style={{color:"#b8ff20"}}>MERCH</b> by <b>LATIN ARTISTS</b> into <b style={{color:"#b8ff20"}}>NFTs</b>.
            </Typography>
            <Typography variant="h5" color="secondary" style={{paddingTop:"30px"}}>
              Now your item receipt has REAL value.
            </Typography>
            {/* <Typography variant="h5" color="secondary">
              for collectors that love unique user experiences.
            </Typography> */}
            <Typography variant="h5" color="secondary">
              👾🎧
            </Typography>
          </Box>
  
          <Box width="100%" pt={1} className={classes.boxDescriptionStyle}>
            {/* <Typography variant="subtitle1" color="secondary">
              <b><span style={{color:"#00BEC5"}}>Summer 2022</span></b>
            </Typography> */}
            <Typography variant="subtitle1"  color="secondary">
              Join now to be part of matiz early acccess list.
            </Typography>
          </Box>
  
          <Box display="flex"  pt={3} pb={4} className={classes.boxInputStyle}>
            <TextField type="email" label="Email" variant="outlined" className={classes.textField} value={userEmail}
              onChange={(event) => {setUserEmail(event.target.value)}}/>
            <Button variant="contained" style={
              {
                width:"100px", 
                height:"50px", 
                color: "white",
                borderRadius: 0,
                backgroundImage: "linear-gradient(to left bottom, #00bec5, #00cfb7, #00dc96, #60e665, #a8eb12)"
                //backgroundImage: "linear-gradient(to left bottom, #00bec5, #00afd8, #009ceb, #0085f5, #0065ec, #5854e1, #7c3ed1, #9719bd, #ac1db8, #be26b3, #ce31ae, #dc3daa)",
              }
            }
              onClick={() => {
                 openWhoRUSection(userEmail); 
              }}>
                Join Us
            </Button>
          </Box>
  
          <Box width="100%" pb={6} className={classes.boxInputStyle}>
            <Typography>
                <Link component="button" variant="subtitle1" onClick={() => {
                  setOpenWaxDialog(true)
                //myRefWeCare.current.scrollIntoView({behavior: "smooth"})
              }}>
                  We Care! 🌳
                </Link>
            </Typography>
          </Box>
  
          <Box display="none" pb={10} justifyContent="center" className={classes.boxOnlyMobile}>
            {/* <TelegramIcon color="secondary" style={{marginRight:"30px" }}/> */}
            <Link href="https://www.instagram.com/matiznft/">
              <InstagramIcon color="secondary" style={{marginRight:"30px"}}/>
            </Link>
            {/* <TwitterIcon color="secondary" style={{marginRight:"30px"}}/>
            <FacebookIcon color="secondary" style={{marginRight:"30px"}}/>
            <LinkedInIcon color="secondary" style={{marginRight:"30px"}}/> */}
          </Box>
        </Box>
  
  
        <Dialog onClose={handleCloseUserRole} open={openUserRoleDialog} maxWidth="lg">
          <Box component="div" justifyContent="center" className={classes.waxDialogBox}>
            <Typography align="center" color="secondary">
              How do you identified yourself?
            </Typography>
            <Box component="div" display="flex" justifyContent="center" pt={5}>
  
              <Box>
                <Card className={classes.cardWhoUser} style={{backgroundColor: userRole == "Collector" ? "#DC3DAA" : null}}>
                <CardActionArea  onClick={()=>{
                  setUserRole("Collector")
                  setUserRegister(true)            
                }}>
                  <CardMedia
                    className={classes.cardmediaWhoUser}
                    component="img"
                    image={userCollector}
                    title="Investor"
                  />
                    <Typography className={classes.typographyUserRole}  align="center" variant="body1" color="primary">
                      Collector
                    </Typography>
                  </CardActionArea>
                </Card>
              </Box>
  
              <Box>
              
                <Card className={classes.cardWhoUser} style={{backgroundColor: userRole == "Creator" ? "#DC3DAA" : null}}>
                <CardActionArea onClick={()=>{
                  setUserRole("Creator")
                  setUserRegister(true)               
                }}>
                  <CardMedia
                    className={classes.cardmediaWhoUser}
                    component="img"
                    image={userCreator}
                    title="Creator"
                  />
                  <Typography className={classes.typographyUserRole} align="center" variant="body1" color="primary">
                    Creator
                  </Typography>
                </CardActionArea>
                </Card>
                
              </Box>
  
              <Box>
                <Card className={classes.cardWhoUser} style={{backgroundColor: userRole == "Investor" ? "#DC3DAA" : null}}>
                <CardActionArea  onClick={()=>{
                  setUserRole("Investor")
                  setUserRegister(true)              
                }}>
                  <CardMedia
                    className={classes.cardmediaWhoUser}
                    component="img"
                    image={userInvestor}
                    title="Investor"
                  />
                  <Typography className={classes.typographyUserRole}  align="center" variant="body1" color="primary">
                    Investor
                  </Typography>
                </CardActionArea>
                </Card>
              </Box>
            </Box>
  
            <Fade in={userRegister}>
              <Typography ref={myRefWhoRU} align="center" color="secondary" style={{paddingTop:"30px"}}>
                  You are in! Thanks!
              </Typography>
            </Fade>
          </Box>
        </Dialog>
  
  
  
        <Dialog onClose={handleClose} open={openWaxDialog} maxWidth="lg">
          <Box component="div" className={classes.waxDialogBox}>
            <Box pt={4}>
              <Typography variant="h5" color="secondary" align="center">
                We Care! 🌳
              </Typography>
            </Box>
  
            <Box ref={myRefWeCare} pt={5} justifyContent="center">
              <Typography variant="h3" className={classes.typographyWeCareStyle}  color="secondary" align="center">
                We are using World Wide Asset eXchange&trade;
              </Typography>
              <Typography variant="h3" className={classes.typographyWeCareStyle}  color="secondary" align="center">
                (WAX), the world's most proven and
              </Typography>
              <Typography variant="h3" className={classes.typographyWeCareStyle}  color="secondary" align="center">
                eco-friendly blockchain for NFTs.
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" py={5} >
              <img src={waximage} alt="WAX" className={classes.imageWaxStyle} style={{maxWidth:"300px"}}/>
            </Box>
            
            {/* <Box pt={2} pb={10}> 
              <Typography variant="subtitle1" color="secondary" align="center">
                We ❤ Latin Artists!
              </Typography>
            </Box> */}
          </Box>
        </Dialog>
  
        
      </div>
    );
  }
  
  export default LandingPage;
  