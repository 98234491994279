import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import pack from '../../assets/common/pack.jpeg'

// Unity
import Unity, { UnityContext } from "react-unity-webgl";

const unityContext = new UnityContext({
  loaderUrl: "build/packOpening.loader.js",
  dataUrl: "build/packOpening.data",
  frameworkUrl: "build/packOpening.framework.js",
  codeUrl: "build/packOpening.wasm",
});

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    rootImageCollection: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    imageList: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
      justifyContent:"center",
      width:"900px"
    },
    imageListItem: {
      height:100
    },
  }));

const DropPackOpening = (props) => {

    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [showPackOpening, setShowPackOpening] = useState(false)
    const [currentPackOpening, setCurrentPackOpening] = useState(0)
    const [listOfPacks, setListOfPacks] = useState()
    const [listOfPackImages, setListOfPackImages] = useState()
    const [openPackText, setOpenPackText] = useState("Open Pack")
    const [unityProgress, setUnityProgress] = useState(0)
    const [disablePackClick, setDisablePackClick] = useState(0)

    const [refScroll, setRefScroll] = useState(React.createRef())

    useEffect(()=>{
      if(props.packs && props.packimages){
        console.log("Packs,", props.packs)
        showPacksAvailable(props.packs, props.packimages)
      }

      unityContext.on("progress", function (progression) {
        if(progression == 1){
          setUnityProgress(progression)
        }
      });

      //When the unity game loads send the packs for this user
      if(unityProgress == 1){
        setTimeout(function(){
          sendPacksToUnity(props.packs)
        }, 1000);
      }

      unityContext.on("PauseMovingPack", function(disable) {
        console.log("here is the handler", disable)
        setDisablePackClick(disable);
      })
    },[props.packs, props.packimages,unityProgress])


    //Check if the user has packages to open
    const showPacksAvailable = (packs, packImages) =>{
      if(packs.length > 0){
        setShowPackOpening(true)
        setListOfPacks(packs)
      }
      if(packImages.length > 0){
        // packImages.push("frefrefrefrefer")
        // packImages.push("frefrefrefrefer")
        // packImages.push("frefrefrefrefer")
        // packImages.push("frefrefrefrefer")
        // packImages.push("frefrefrefrefer")
        // packImages.push("frefrefrefrefer")
        // packImages.push("frefrefrefrefer")
        // packImages.push("frefrefrefrefer")
        // packImages.push("frefrefrefrefer")
        setListOfPackImages(packImages)
      }
    }

    //Communicate with unity to open the pack shown.
    const openPackNow = () => {
      //console.log("Opening Pack ", currentPackOpening)
      if(listOfPacks[currentPackOpening] != undefined){
        unityContext.send("Pack", "openPack",listOfPacks[currentPackOpening])
        setCurrentPackOpening(currentPackOpening)

      }else{
        setShowPackOpening(false)
        handleClose()
      }
    };

    const sendPacksToUnity = (packs) => {
      console.log("send packs to unity", packs.length)
      unityContext.send("Pack", "createPacks", packs.length);
    }

    //Communicate with Unity to switch packs
    const switchPack = (packNumber) => {
      if(disablePackClick < 1){
        unityContext.send("Pack", "changePack", packNumber);
        setCurrentPackOpening(packNumber)
      }
    }

    //Handle open of the model
    const handleOpen = () => {
        setOpen(true);
    };

    //Handle close of the model
    const handleClose = () => {
        setOpen(false);
    };

    return(
        <div  style={{width:"100%", justifyContent:"center", display:"flex"}}>
            {showPackOpening && <Button onClick={handleOpen}  variant="contained" color="primary">
                Open Your Packs
            </Button>}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Fade in={open}>
                  <div className={classes.paper}>
                    <div style={{marginBottom:"10px"}}>
                    <Unity style={{height:"500px", width:"900px"}} unityContext={unityContext} />
                    </div>
                    <div className={classes.rootImageCollection}>
                      <ImageList  cols={2.5}>
                        {listOfPackImages && listOfPackImages.map((item, index) => {
                          return <ImageListItem style={{ width:"100px"}} className={classes.imageListItem} key={item} onClick={() => switchPack(index)}>
                            <img src={pack} alt={"Pack Title"} />
                          </ImageListItem>
                        })}
                      </ImageList>
                    </div>
                    <div style={{alignItems: "center", justifyContent:"center", display:"flex"}}>
                      <Button variant="contained" color="primary" onClick={openPackNow}>{openPackText}</Button>
                    </div>
                  </div>
                </Fade>
            </Modal>
        </div>
    )
}

export default DropPackOpening;